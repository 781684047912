<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="3">
        <leaderboard-card></leaderboard-card>
      </v-col>
      <v-col cols="4" class="pl-4" style="min-height: calc(100vh - 140px);">
        <awarding-badges-card></awarding-badges-card>
      </v-col>
      <v-col cols="5" class="pl-4">
        <badge-management-card></badge-management-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const LeaderboardCard = () =>
  import("../cards/LeaderboardCard.vue");
const AwardingBadgesCard = () =>
  import("../cards/AwardingBadgesCard.vue");
const BadgeManagementCard = () =>
  import("../cards/BadgeManagementCard.vue");

export default {
  components: {
    BadgeManagementCard,
    LeaderboardCard,
    AwardingBadgesCard
  }
};
</script>